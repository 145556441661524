<template>
  <v-container fluid>
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="alert"
      transition="fade-transition"
      type="warning"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="alert = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ alertMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        Successfully Submitted
      </div>
    </v-alert>

    <v-row v-show="loading || submitting">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
        
        <v-btn v-if="show_back_btn" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon> 
          Go Back
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!loading" v-show="!submitting" justify="center">
      <v-col>
        <div v-if="viewed_as === 'create' || viewed_as === 'create_from_deal'">
          <h2>
            Customer Registration
            <v-icon>mdi-account-plus</v-icon>
          </h2>

          <p class="grey--text">
            To get started, please fill out the form below to create the new
            customer. Ensure accuracy in the provided information for a seamless
            experience.
          </p>
        </div>

        <div v-if="viewed_as === 'update' || viewed_as === 'update_from_deal'">
          <h2>
            Update {{ customer.first_name }} {{ customer.last_name }}'s
            Information
            <v-icon>mdi-account-clock</v-icon>
          </h2>

          <p class="grey--text">
            Keep the customer details up-to-date for a better experience. Use
            the form below to modify all information that you wish to update.
          </p>
        </div>

        <AddCustomer
          :viewed_as="viewed_as"
          :existing_customer="customer"
          :existing_contact_infos="existing_contact_infos"
          @handle_submit="handle_submit"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import AddCustomer from "@/components/customer/AddCustomer.vue";

export default {
  name: "CreateCustomer",

  components: { AddCustomer },

  props: {
    viewed_as: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    error: false,
    errorMessage: "",
    alert: false,
    alertMessage: "",
    submitted: false,
    submitting: false,
    show_back_btn: false,

    customer: null,
    existing_contact_infos: [],
  }),

  methods: {
    validate_data(value) {
      // basic information
      // contact information
      const has_email = value.contact_infos.some(
        (item) => item.type === "Email"
      );

      const has_number = value.contact_infos.some(
        (item) =>
          item.type === "Cell Phone Number" ||
          item.type === "Home Phone Number" ||
          item.type === "Work Phone Number"
      );

      if (!has_email) {
        this.error = true;
        this.errorMessage = "At least one email is required";
        return false;
      }

      if (has_email) {
        const email_has_value = value.contact_infos.some(
          (item) => item.type === "Email" && item.email !== null
        );

        if (!email_has_value) {
          this.error = true;
          this.errorMessage = "At least one email is required";
          return false;
        }
      }

      if (!has_number) {
        this.error = true;
        this.errorMessage = "At least one phone number is required";
        return false;
      }

      if (has_number) {
        const number_has_value = value.contact_infos.some(
          (item) =>
            (item.type === "Cell Phone Number" ||
              item.type === "Home Phone Number" ||
              item.type === "Work Phone Number") &&
            item.number !== null
        );

        if (!number_has_value) {
          this.error = true;
          this.errorMessage = "At least one phone number is required";
          return false;
        }
      }

      // address
      const has_present_address = value.address_data.some(
        (item) => item.present
      );

      if (value.address_data.length === 0) {
        this.error = true;
        this.errorMessage = "At least one address is required";
        return false;
      }

      if (!has_present_address && value.address_data.length > 0) {
        this.error = true;
        this.errorMessage = "Please provide a present address";
        return false;
      }

      return true;
    },

    async handle_submit(value) {
      try {
        this.submitting = true;

        const valid = this.validate_data(value);

        if (!valid) {
          this.submitting = false;
          return;
        }

        let response = null;

        if (
          this.viewed_as === "create" ||
          this.viewed_as === "create_from_deal"
        ) {
          response = await API().post("api/internal-admin/customer/create", {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            customer: value,
          });

          // Associate customer to deal if viewed as create from deal
          if (this.viewed_as === "create_from_deal") {
            const associate_response = await API().post(
              "api/internal-admin/customer/associate_customer_to_deal",
              {
                deal_id: Number(this.$route.query.deal_id),
                customer_id: Number(response.data.id),
              }
            );
          }
        }

        if (
          this.viewed_as === "update" ||
          this.viewed_as === "update_from_deal"
        ) {
          // update
          response = await API().patch("api/internal-admin/customer/update", {
            customer_id: Number(this.$route.query.customer_id),
            customer: value,
            user_id: this.$store.getters["Auth/getAuthUser"].id,
          });

          console.log(response);
        }

        if (response.status === 201) {
          this.submitted = true;

          setTimeout(() => {
            switch (this.viewed_as) {
              case "create":
                this.$router.push(
                  `/dashboard/admin/customer/list-all-customers`
                );
                break;

              case "update":
                this.$router.push(
                  `/dashboard/admin/customer/read/${this.$route.query.customer_id}`
                );
                break;

              case "create_from_deal":
              case "update_from_deal":
                this.$router.push(
                  `/dashboard/admin/deal/read/${this.$route.query.deal_id}`
                );
                break;

              default:
                // Handle other cases or do nothing
                break;
            }

            this.submitting = false;
          }, 3000);
        }
      } catch (error) {
        this.submitting = false;
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    async get_data() {
      if (
        this.viewed_as === "update" ||
        this.viewed_as === "update_from_deal"
      ) {
        try {
          this.loading = true;

          const response = await API().get(
            `api/internal-admin/customer/read_one_customer?customer_id=${Number(
              this.$route.query.customer_id
            )}`
          );

          // console.log(response);

          if (response.status === 200) {
            this.customer = response.data;
            this.loading = false;
          }

          if (response.status !== 200) {
            console.log("No Customer Found.");
            this.alert = true;
            this.alertMessage = "No Customer Found.";
            this.show_back_btn = true;
          }
        } catch (error) {
          console.log(error.response.data.message);
          this.error = true;
          this.errorMessage = error.response.data.message;
        }
      }
    },

    async get_existing_contact_info() {
      try {
        const response = await API().get(
          "api/internal-admin/customer/read_all_contact_infos"
        );

        // console.log(response);

        if (response.status === 200) {
          this.existing_contact_infos = response.data.filter(
            (item) => item.customers.length > 0
          );
        }

        if (response.status !== 200) {
          console.log("No Existing Contact Infos Found.");
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },
  },

  created() {
    this.get_data();
    this.get_existing_contact_info();
  },
};
</script>

<style scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
