<template>
  <v-form v-model="new_asset_is_valid">
    <v-card>
      <v-card-title>
        {{ adding_asset ? "Adding Asset" : "Updating Asset" }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="new_asset_data.asset_type"
              :items="asset_type_options"
              label="Asset Type"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_asset_data.value"
              label="Value"
              outlined
              hide-details="auto"
              @blur="
                new_asset_data.value = formatCurrency(new_asset_data.value)
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              v-model="new_asset_data.additional_description"
              label="Additional Description"
              outlined
              auto-grow
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="adding_asset"
          :disabled="!new_asset_is_valid"
          color="primary"
          @click="$emit('save_add', new_asset_data)"
        >
          save
        </v-btn>

        <v-btn
          v-if="updating_asset"
          :disabled="!new_asset_is_valid"
          color="primary"
          @click="$emit('save_update', new_asset_data)"
        >
          update
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "Addasset",

  props: {
    adding_asset: {
      type: Boolean,
    },

    updating_asset: {
      type: Boolean,
    },

    rule: {
      type: Object,
    },

    asset_data: {
      type: Object,
    },
  },

  data: () => ({
    new_asset_is_valid: false,

    // asset options
    asset_type_options: [
      "Savings",
      "RRSP",
      "Gift",
      "Vehicle",
      "Stocks / Bonds / Mutual",
      "Household Goods",
      "Life Insurance",
      "Deposit on Purchase",
      "Other",
    ],

    // initialize asset data
    new_asset_data: {
      asset_type: null,
      value: null,
      additional_description: null,
      archived: false,
    },
  }),

  methods: {
    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    if (this.updating_asset) {
      this.new_asset_data = this.asset_data;
      console.log(this.new_asset_data);
    }
  },
};
</script>
