<template>
  <div class="d-flex justify-center">
    <!-- confirmations -->

    <v-dialog v-model="confirming_contact_info" max-width="500">
      <v-card>
        <v-card-title class="headline orange--text">
          <v-icon class="mr-4 orange--text"> mdi-alert-outline </v-icon>
          {{ temp_contact_info ? temp_contact_info.type : "" }}
          Confirmation</v-card-title
        >

        <v-card-text>
          <div class="mt-5 text-body-1">
            <p>
              The {{ temp_contact_info ? temp_contact_info.type : "" }} you are
              trying to add is currently used by the following customer:
            </p>

            <div
              v-for="customer in temp_contact_info
                ? temp_contact_info.customers
                : []"
              :key="customer.id"
            >
              <p>• {{ customer.first_name }} {{ customer.last_name }}</p>
            </div>

            <p>
              Please confirm if you wish to use the same
              {{ temp_contact_info ? temp_contact_info.type : "" }} this phone
              number will be associated with multiple customers.
            </p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="handle_confirm_contact_info">
            Confirm
          </v-btn>

          <v-btn
            clss="ml-5"
            color="red"
            text
            @click="handle_close_confirm_contact_info"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Side Navigation -->
    <div class="mr-2">
      <div class="side-nav">
        SECTIONS:
        <v-list dense>
          <!-- Navigation Links -->
          <v-list-item
            @click="
              if (!panels.includes(0)) panels.push(0);
              scrollToElement($refs.basic_information.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Basic Information </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(1)) panels.push(1);
              scrollToElement($refs.financial_information.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Financial Information </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              scrollToElement($refs.relationship.$el);
              if (!panels.includes(2)) panels.push(2);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Relationship </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(3)) panels.push(3);
              scrollToElement($refs.identification.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Identification </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(4)) panels.push(4);
              scrollToElement($refs.address.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Address </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(5)) panels.push(5);
              scrollToElement($refs.employment.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Employment </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(6)) panels.push(6);
              scrollToElement($refs.properties.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Properties </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(7)) panels.push(7);
              scrollToElement($refs.additional_income.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Additional Income </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(8)) panels.push(8);
              scrollToElement($refs.asset.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Assets </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(9)) panels.push(9);
              scrollToElement($refs.liabilities.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Liabilities </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              panels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
              scrollToElement($refs.basic_information.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title class="green--text">
                Epand All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="panels = []">
            <v-list-item-content>
              <v-list-item-title class="red--text">
                Collapse All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>

    <div>
      <v-expansion-panels v-model="panels" multiple>
        <!-- Basic information -->
        <v-expansion-panel ref="basic_information" class="bordered">
          <v-expansion-panel-header>
            Basic Information
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-form class="mt-5">
              <v-row>
                <v-col>
                  <v-select
                    v-model="customer.salutation"
                    :items="salutation_options"
                    label="Salutation"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="customer.first_name"
                    label="First Name"
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="customer.middle_name"
                    label="Middle Name"
                    outlined
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="customer.last_name"
                    label="Last Name"
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-menu
                    v-model="birthday_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="customer.birthday"
                        label="Date of Birth"
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rule.date"
                        hint="YYYY-MM-DD format"
                        append-icon="mdi-calendar-blank"
                      />
                    </template>
                    <v-date-picker
                      no-title
                      v-model="customer.birthday"
                      @input="birthday_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-divider class="my-5" />
              <p class="grey--text text-subtitle-2">Contact Information :</p>

              <v-row>
                <v-col>
                  <v-alert
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3"
                        >mdi-information-outline</v-icon
                      >
                      <span class="ml-2">
                        Any additional or changes in this contact information
                        will be saved only after clicking submit button.
                      </span>
                    </div>
                  </v-alert>

                  <v-row
                    v-for="(contact_info, index) in customer.contact_infos"
                    :key="contact_info.order"
                    class="mb-5"
                  >
                    <v-col cols="4">
                      <v-select
                        v-model="contact_info.type"
                        :items="contact_type_options"
                        label="Contact Type"
                        hide-details="auto"
                        @change="
                          contact_info.email = null;
                          contact_info.number = null;
                          contact_info.note = null;
                        "
                      />
                    </v-col>

                    <v-col v-if="contact_info.type === 'Email'">
                      <v-text-field
                        v-model="contact_info.email"
                        label="Email"
                        :rules="rule.email"
                        outlined
                        @change="check_if_contact_info_exist(index)"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col
                      v-if="
                        contact_info.type === 'Home Phone Number' ||
                        contact_info.type === 'Work Phone Number' ||
                        contact_info.type === 'Cell Phone Number'
                      "
                    >
                      <v-text-field
                        v-model="contact_info.number"
                        :label="contact_info.type"
                        :rules="rule.phone"
                        outlined
                        maxlength="10"
                        @change="check_if_contact_info_exist(index)"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col v-if="contact_info.type">
                      <v-textarea
                        v-model="contact_info.note"
                        label="Note"
                        outlined
                        rows="1"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="1">
                      <v-btn
                        class="mt-2"
                        color="red"
                        icon
                        title="Delete"
                        @click="customer.contact_infos.splice(index, 1)"
                      >
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-btn @click="handle_add_contact_info"> + Add More </v-btn>

                  <v-btn
                    v-if="customer.phone !== null"
                    class="ml-2"
                    @click="handle_add_old_data('phone')"
                  >
                    Copy Old Phone
                  </v-btn>

                  <v-btn
                    v-if="customer.email !== null"
                    class="ml-2"
                    @click="handle_add_old_data('email')"
                  >
                    Copy Old Email
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="my-5" />
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="customer.social_insurance_number"
                    label="Social Insurance Number"
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="customer.language_preference"
                    :items="language_preference_options"
                    label="Language Preference"
                    hide-details="auto"
                  />
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    v-model="customer.dependents"
                    type="number"
                    :rule="rule.number"
                    label="Dependents"
                    outlined
                    hide-details="auto"
                    @blur="
                      customer.dependents === ''
                        ? (customer.dependents = null)
                        : (customer.dependents = customer.dependents)
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5">
                  <v-select
                    v-model="customer.marital_status"
                    :items="marital_status_options"
                    label="Marital Status"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-textarea
                    v-model="customer.marital_status_note"
                    label="Marital Status Note"
                    outlined
                    auto-grow
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="customer.current_residential_status"
                    label="Current Residential Status"
                    :items="residential_status_options"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-divider />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-switch
                    v-model="customer.send_email_status_communications"
                    label="Send Email Status"
                    dense
                  />
                </v-col>

                <v-col cols="3">
                  <v-switch
                    v-model="customer.first_home"
                    label="First Home"
                    dense
                  />
                </v-col>

                <v-col v-if="viewed_as === 'update'">
                  <v-switch v-model="customer.archived" label="Archive" dense />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Financial Information  -->
        <v-expansion-panel ref="financial_information" class="mt-5 bordered">
          <v-expansion-panel-header>
            <div>Financial Information</div>
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-form>
              <v-row class="mt-2">
                <v-col cols="3">
                  <v-switch
                    v-model="customer.power_of_attorney"
                    ref="power_of_attorney"
                    label="Power of Attorney"
                    hide-details="auto"
                  />
                </v-col>

                <!-- power of attorney notes -->
                <v-col v-if="customer.power_of_attorney">
                  <div v-if="customer.poa_notes.length > 0">
                    <v-alert
                      color="orange darken-3"
                      dense
                      transition="fade-transition"
                      outlined
                    >
                      <div class="d-flex align-center">
                        <v-icon color="orange darken-3"
                          >mdi-information-outline</v-icon
                        >
                        <span class="ml-2">
                          Any additional or changes in this power of attorney
                          note will be saved only after clicking submit button.
                        </span>
                      </div>
                    </v-alert>

                    <p>Power of Attorney Notes:</p>

                    <v-data-table
                      v-model="selected_poa_notes"
                      :headers="header_note"
                      item-key="order"
                      @click:row="handle_updating_poa_note($event)"
                      :items="customer.poa_notes"
                      :items-per-page="5"
                      show-select
                    />

                    <v-btn
                      class="mt-2"
                      color="error"
                      v-if="selected_poa_notes.length > 0"
                      @click="handle_delete_poa_note"
                    >
                      Delete
                    </v-btn>
                  </div>

                  <v-btn
                    class="mt-2"
                    v-if="!adding_poa_note && !updating_poa_note"
                    @click="adding_poa_note = true"
                  >
                    add note
                  </v-btn>

                  <v-form v-model="valid_poa_note">
                    <v-card
                      class="mt-5"
                      v-if="adding_poa_note || updating_poa_note"
                    >
                      <v-card-title class="text-body-1">
                        {{ adding_poa_note ? "Adding" : "Updating" }}
                        Power of Attorney Note
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-textarea
                              v-model="new_poa_note.note"
                              label="Note"
                              outlined
                              auto-grow
                              hide-details="auto"
                              :rules="rule.required"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          @click="
                            adding_poa_note
                              ? handle_save_poa_note()
                              : handle_save_update_poa_note()
                          "
                          :disabled="!valid_poa_note"
                        >
                          save
                        </v-btn>

                        <v-btn
                          @click="
                            adding_poa_note = false;
                            updating_poa_note = false;
                            new_poa_note = { note: '' };
                          "
                        >
                          cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-switch
                    v-model="customer.required_mortgage_suitability_review"
                    label="Required Mortgage Suitability Review? "
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <!-- credit scores -->
              <v-row>
                <v-col>
                  <v-switch
                    v-model="customer.no_credit_pulled"
                    label="No Credit Pulled"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row v-if="!customer.no_credit_pulled">
                <v-col>
                  <v-alert
                    color="orange darken-3"
                    v-show="customer.credit_scores.length > 0"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3"
                        >mdi-information-outline</v-icon
                      >
                      <span class="ml-2">
                        Any additional or changes in credit score will be saved
                        only after clicking submit button.
                      </span>
                    </div>
                  </v-alert>

                  <v-data-table
                    v-if="customer.credit_scores.length > 0"
                    :headers="header_credit_score"
                    v-model="selected_credit_score"
                    item-key="order"
                    :items="customer.credit_scores"
                    :items-per-page="5"
                    @click:row="handle_updating_credit_score($event)"
                    show-select
                  >
                    <template v-slot:item.date_pulled="{ item }">
                      {{ item.date_pulled | formatDate }}
                    </template>
                  </v-data-table>

                  <v-btn
                    v-if="selected_credit_score.length > 0"
                    @click="handle_delete_credit_score"
                    color="error"
                    class="mt-2"
                  >
                    delete
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="!customer.no_credit_pulled">
                <v-col>
                  <v-btn
                    v-if="!adding_credit_score && !updating_credit_score"
                    @click="adding_credit_score = true"
                  >
                    add credit score
                  </v-btn>

                  <v-form v-model="valid_credit_score">
                    <v-card
                      v-if="adding_credit_score || updating_credit_score"
                      class="mt-5"
                    >
                      <v-card-title>
                        {{ adding_credit_score ? "Adding" : "Updating" }} Credit
                        Score
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="new_credit_score.credit_score"
                              label="Credit Score"
                              maxlength="4"
                              :rules="[
                                (v) =>
                                  (!isNaN(v) && v <= 1000) ||
                                  'Input must be a number and less than or equal to 1000',
                              ]"
                              outlined
                              hide-details="auto"
                            />
                          </v-col>

                          <v-col>
                            <v-menu
                              v-model="date_pulled_menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="new_credit_score.date_pulled"
                                  label="Date Pulled"
                                  outlined
                                  hide-details="auto"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="rule.date"
                                  hint="YYYY-MM-DD format"
                                  append-icon="mdi-calendar-blank"
                                />
                              </template>
                              <v-date-picker
                                no-title
                                v-model="new_credit_score.date_pulled"
                                @input="date_pulled_menu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          @click="
                            adding_credit_score
                              ? handle_save_credit_score()
                              : handle_save_update_credit_score()
                          "
                          :disabled="!valid_credit_score"
                        >
                          save
                        </v-btn>

                        <v-btn
                          @click="
                            adding_credit_score = false;
                            updating_credit_score = false;
                            new_credit_score = {
                              credit_score: null,
                              date_pulled: null,
                            };
                          "
                        >
                          cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- relationship -->
        <v-expansion-panel ref="relationship" class="bordered mt-5">
          <v-expansion-panel-header> Relationship </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row class="mt-5" v-if="customer.relationship_data.length > 0">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in relationship will be saved
                      only after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text mb-2">List of added relationship:</h5>

                <v-data-table
                  :headers="header_relationship"
                  item-key="order"
                  :items="customer.relationship_data"
                  :items-per-page="5"
                  v-model="selected_relationship"
                  @click:row="handle_updating_relationship($event)"
                  show-select
                >
                  <template v-slot:item.name="{ item }">
                    <span>
                      {{ item.cust_2.last_name }},
                      {{ item.cust_2.first_name }}
                      {{ item.cust_2.middle_name }}
                    </span>
                  </template>
                </v-data-table>

                <v-btn
                  v-if="selected_relationship.length > 0"
                  @click="handle_delete_relationship()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_relationship && !updating_relationship"
                  @click="adding_relationship = true"
                >
                  add relationship
                </v-btn>

                <Relationship
                  v-if="adding_relationship || updating_relationship"
                  :adding_relationship="adding_relationship"
                  :updating_relationship="updating_relationship"
                  :relationship_data="customer.relationship_data"
                  :existing_relationship_data="customer.new_relationship_data"
                  :customers="
                    customers.filter((el) =>
                      existing_customer ? el.id !== existing_customer.id : true
                    )
                  "
                  :rule="rule"
                  @save_add="
                    adding_relationship
                      ? handle_save_relationship($event)
                      : handle_save_update_relationship($event)
                  "
                  @close="
                    adding_relationship = false;
                    updating_relationship = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- identifations -->
        <v-expansion-panel ref="identification" class="bordered mt-5">
          <v-expansion-panel-header> Identifications </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <!-- list of all added identifications -->
            <v-row v-if="customer.identification_data.length > 0" class="mt-5">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in identification will be saved
                      only after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text mb-2">List of added identification:</h5>

                <v-data-table
                  :headers="header_identifications"
                  item-key="order"
                  :items="
                    customer.identification_data.filter((el) => !el.archived)
                  "
                  :items-per-page="5"
                  @click:row="handle_updating_identification($event)"
                  v-model="selected_identification"
                  show-select
                >
                  <template v-slot:[`item.expiry_date`]="{ item }">
                    {{ item.expiry_date | formatDate }}
                  </template>
                </v-data-table>

                <v-btn
                  v-if="selected_identification.length > 0"
                  @click="handle_delete_identification()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_identification && !updating_identification"
                  @click="adding_identification = true"
                >
                  add identification
                </v-btn>

                <Identification
                  v-if="adding_identification || updating_identification"
                  :adding_identification="adding_identification"
                  :updating_identification="updating_identification"
                  :identification_data="customer.new_identification_data"
                  :rule="rule"
                  @save_add="handle_save_identification"
                  @save_update="handle_save_update_identification"
                  @close="
                    adding_identification = false;
                    updating_identification = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- address information -->
        <v-expansion-panel ref="address" class="bordered mt-5">
          <v-expansion-panel-header>
            Addresses
            <v-spacer />
            <span
              class="d-flex justify-end"
              :style="
                address_time_at_residence(customer.address_data).year > 2
                  ? 'color: green'
                  : 'color: orange'
              "
            >
              Time at residence:
              {{ address_time_at_residence(customer.address_data).text }}
            </span>
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row v-if="customer.address_data.length > 0" class="mt-5">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in address will be saved only
                      after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text mb-2">List of added address:</h5>
                <v-data-table
                  :headers="header_address"
                  item-key="order"
                  :items="customer.address_data.filter((el) => !el.archived)"
                  :items-per-page="5"
                  @click:row="handle_updating_address($event)"
                  v-model="selected_address"
                  show-select
                />

                <v-btn
                  v-if="selected_address.length > 0"
                  @click="handle_delete_address()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_address && !updating_address"
                  @click="adding_address = true"
                >
                  add address
                </v-btn>

                <Address
                  v-if="adding_address || updating_address"
                  :adding_address="adding_address"
                  :updating_address="updating_address"
                  :address_data="customer.new_address_data"
                  :main_address_data="customer.address_data"
                  :rule="rule"
                  @save_add="handle_save_address"
                  @save_update="handle_save_update_address"
                  @close="
                    adding_address = false;
                    updating_address = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- employment information -->
        <v-expansion-panel ref="employment" class="bordered mt-5">
          <v-expansion-panel-header>
            Employment Information

            <v-spacer />
            <span
              class="d-flex justify-end"
              :style="
                time_at_job(customer.employment_data).year > 2
                  ? 'color: green'
                  : 'color: orange'
              "
            >
              Total years of employment:
              {{ time_at_job(customer.employment_data).text }}
            </span>
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row v-if="customer.employment_data.length > 0" class="mt-5">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in employment will be saved only
                      after clicking submit button.
                    </span>
                  </div>
                </v-alert>
                <h5 class="grey--text mb-2">List of added employment:</h5>

                <v-data-table
                  :headers="header_employment"
                  item-key="order"
                  :items="customer.employment_data.filter((el) => !el.archived)"
                  :items-per-page="5"
                  @click:row="handle_updating_employment($event)"
                  v-model="selected_employment"
                  show-select
                />

                <v-btn
                  v-if="selected_employment.length > 0"
                  @click="handle_delete_employment()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_employment && !updating_employment"
                  @click="adding_employment = true"
                >
                  add employment
                </v-btn>

                <Employment
                  v-if="adding_employment || updating_employment"
                  :adding_employment="adding_employment"
                  :updating_employment="updating_employment"
                  :employment_data="customer.new_employment_data"
                  :main_employment_data="customer.employment_data"
                  :rule="rule"
                  :viewed_as="viewed_as"
                  @save_add="handle_save_employment"
                  @save_update="handle_save_update_employment"
                  @close="
                    adding_employment = false;
                    updating_employment = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- properties -->
        <v-expansion-panel ref="properties" class="bordered mt-5">
          <v-expansion-panel-header> Properties </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row v-if="customer.property_data.length > 0" class="mt-5">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in property will be saved only
                      after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text mb-2">List of added property:</h5>

                <v-data-table
                  :headers="header_property"
                  item-key="order"
                  :items="customer.property_data.filter((el) => !el.archived)"
                  :items-per-page="5"
                  @click:row="handle_updating_property($event)"
                  v-model="selected_property"
                  show-select
                >
                  <template v-slot:[`item.purchase_price`]="{ item }">
                    {{ item.purchase_price }}
                  </template>
                  <template v-slot:[`item.purchase_date`]="{ item }">
                    {{ item.purchase_date | formatDate }}
                  </template>
                </v-data-table>

                <v-btn
                  v-if="selected_property.length > 0"
                  @click="handle_unassociate_property()"
                  color="error"
                  class="mt-2"
                >
                  unassociate
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_property && !updating_property"
                  @click="
                    open_property_dialog = true;
                    adding_property = true;
                  "
                >
                  add property
                </v-btn>

                <v-dialog
                  v-model="open_property_dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable
                >
                  <v-card
                    v-show="adding_property || updating_property"
                    style="background-color: #111"
                  >
                    <v-card-title class="orange darken-3 d-flex align-center justify-space-between">
                      <span>
                        {{
                          adding_property ? "Add Property" : "Update Property"
                        }}
                      </span>
                      <v-btn
                        icon
                        small
                        @click="
                          adding_property = false;
                          updating_property = false;
                          open_property_dialog = false;
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-row justify="center">
                        <v-col xs="12" xl="8">
                          <Add_Propertty
                            :viewed_as="viewed_as"
                            :adding_property="adding_property"
                            ref="UpdateProperty"
                            :property_data="customer.new_property_data"
                            @handle_submit="
                              adding_property
                                ? handle_save_property($event)
                                : handle_save_update_property($event)
                            "
                            :existing_addresses="customer.address_data"
                            @handle_cancel="
                              adding_property = false;
                              updating_property = false;
                              open_property_dialog = false;
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- additional income -->
        <v-expansion-panel ref="additional_income" class="bordered mt-5">
          <v-expansion-panel-header>
            Additional Income
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row
              v-if="customer.additional_income_data.length > 0"
              class="mt-5"
            >
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in additional income will be
                      saved only after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text mb-2">
                  List of added additional income:
                </h5>

                <v-data-table
                  :headers="header_additional_income"
                  item-key="order"
                  :items="
                    customer.additional_income_data.filter((el) => !el.archived)
                  "
                  @click:row="handle_updating_additional_income($event)"
                  v-model="selected_additional_income"
                  show-select
                />

                <v-btn
                  v-if="selected_additional_income.length > 0"
                  @click="handle_delete_additional_income()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="
                    !adding_additional_income && !updating_additional_income
                  "
                  @click="adding_additional_income = true"
                >
                  add additional income
                </v-btn>

                <AdditionalIncome
                  v-if="adding_additional_income || updating_additional_income"
                  :adding_additional_income="adding_additional_income"
                  :updating_additional_income="updating_additional_income"
                  :additional_income_data="customer.new_additional_income_data"
                  :rule="rule"
                  @save_add="handle_save_additional_income"
                  @save_update="handle_save_update_additional_income"
                  @close="
                    adding_additional_income = false;
                    updating_additional_income = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- assets -->
        <v-expansion-panel ref="asset" class="bordered mt-5">
          <v-expansion-panel-header> Assets </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row v-if="customer.asset_data.length > 0" class="mt-5">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in asset will be saved only
                      after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text">List of added assets:</h5>

                <v-data-table
                  :headers="header_asset"
                  item-key="order"
                  :items="customer.asset_data.filter((el) => !el.archived)"
                  @click:row="handle_updating_asset($event)"
                  v-model="selected_asset"
                  show-select
                />

                <v-btn
                  v-if="selected_asset.length > 0"
                  @click="handle_delete_asset()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_asset && !updating_asset"
                  @click="adding_asset = true"
                >
                  add asset
                </v-btn>

                <Asset
                  v-if="adding_asset || updating_asset"
                  :adding_asset="adding_asset"
                  :updating_asset="updating_asset"
                  :asset_data="customer.new_asset_data"
                  :rule="rule"
                  @save_add="handle_save_asset"
                  @save_update="handle_save_update_asset"
                  @close="
                    adding_asset = false;
                    updating_asset = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- liabilities -->
        <v-expansion-panel ref="liabilities" class="bordered mt-5">
          <v-expansion-panel-header> Liabilities </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-row v-if="customer.liability_data.length > 0" class="mt-5">
              <v-col>
                <v-alert
                  color="orange darken-3"
                  dense
                  transition="fade-transition"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="orange darken-3"
                      >mdi-information-outline</v-icon
                    >
                    <span class="ml-2">
                      Any additional or changes in liabilities will be saved
                      only after clicking submit button.
                    </span>
                  </div>
                </v-alert>

                <h5 class="grey--text">List of added liabilities:</h5>

                <v-data-table
                  :headers="header_liability"
                  item-key="order"
                  :items="customer.liability_data.filter((el) => !el.archived)"
                  @click:row="handle_updating_liability($event)"
                  v-model="selected_liability"
                  show-select
                >
                  <template v-slot:[`item.single`]="{ item }">
                    {{ item.single ? "Joint" : "Single" }}
                  </template>

                  <template v-slot:[`item.maturity_date`]="{ item }">
                    {{ item.single | formatDate }}
                  </template>
                </v-data-table>

                <v-btn
                  v-if="selected_liability.length > 0"
                  @click="handle_delete_liability()"
                  color="error"
                  class="mt-2"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!adding_liability && !updating_liability"
                  @click="adding_liability = true"
                >
                  add liability
                </v-btn>

                <Liability
                  v-if="adding_liability || updating_liability"
                  :adding_liability="adding_liability"
                  :updating_liability="updating_liability"
                  :liability_data="customer.new_liability_data"
                  :rule="rule"
                  @save_add="handle_save_liability"
                  @save_update="handle_save_update_liability"
                  @close="
                    adding_liability = false;
                    updating_liability = false;
                  "
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="mt-5">
        <v-btn
          v-if="viewed_as === 'create' || viewed_as === 'create_from_deal'"
          color="primary"
          @click="handle_submit"
        >
          submit
        </v-btn>

        <v-btn
          v-if="viewed_as === 'update' || viewed_as === 'update_from_deal'"
          color="primary"
          @click="handle_submit"
        >
          update
        </v-btn>

        <v-btn class="ml-2" @click="handle_cancel"> cancel </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/plugins/API";
import Relationship from "@/components/customer/AddRelationship.vue";
import Identification from "@/components/customer/AddIdentification.vue";
import Address from "@/components/customer/AddAddress.vue";
import Employment from "@/components/customer/AddEmployment.vue";
import Add_Propertty from "@/components/property/Add_Property.vue";
import AdditionalIncome from "@/components/customer/AddAdditionalIncome.vue";
import Asset from "@/components/customer/AddAsset.vue";
import Liability from "@/components/customer/AddLiability.vue";
import filt from "@/plugins/filters";

export default {
  name: "AddCustomer",

  components: {
    Relationship,
    Identification,
    Address,
    Employment,
    Add_Propertty,
    AdditionalIncome,
    Asset,
    Liability,
  },

  props: {
    viewed_as: {
      type: String,
      required: true,
    },

    existing_customer: {
      type: Object,
    },

    existing_contact_infos: {
      type: Array,
    },
  },

  data: () => ({
    loading: false,
    error: false,
    formIsSubmitted: false,
    errorMessage: "",

    birthday_menu: false,
    date_pulled_menu: false,
    open_property_dialog: false,
    confirming_contact_info: false,

    // for contact_info
    new_contact_info: {
      type: null,
      number: null,
      note: null,
      email: null,
    },

    temp_contact_info: null,

    // for financial information
    // -- business self note
    adding_business_self_note: false,
    updating_business_self_note: false,
    selected_business_self_notes: [],
    new_business_self_note: {
      note: "",
    },
    valid_business_self_note: false,

    // -- variable income note
    adding_variable_income_note: false,
    updating_variable_income_note: false,
    selected_variable_income_notes: [],
    new_variable_income_note: {
      note: "",
    },
    valid_variable_income_note: false,

    // -- poa note
    adding_poa_note: false,
    updating_poa_note: false,
    selected_poa_notes: [],
    new_poa_note: {
      note: "",
    },
    valid_poa_note: false,

    adding_credit_score: false,
    updating_credit_score: false,
    selected_credit_score: [],
    new_credit_score: {
      credit_score: null,
      date_pulled: null,
    },
    new_credit_score_order: null,
    valid_credit_score: false,

    // for relationship
    adding_relationship: false,
    updating_relationship: false,
    selected_relationship: [],

    // for identification
    adding_identification: false,
    updating_identification: false,
    selected_identification: [],
    selected_archived_identification: [],

    // for address
    adding_address: false,
    updating_address: false,
    selected_address: [],
    selected_archived_address: [],

    // for employment
    adding_employment: false,
    updating_employment: false,
    selected_employment: [],
    selected_archived_employment: [],

    // for properties
    adding_property: false,
    updating_property: false,
    selected_property: [],
    selected_archived_property: [],

    // for additional income
    adding_additional_income: false,
    updating_additional_income: false,
    selected_additional_income: [],
    selected_archived_additional_income: [],

    // for assets
    adding_asset: false,
    updating_asset: false,
    selected_asset: [],
    selected_archived_asset: [],

    // for liabilities
    adding_liability: false,
    updating_liability: false,
    selected_liability: [],
    selected_archived_liability: [],

    // rules
    rule: {
      required: [(v) => !!v || "This is required"],
      phone: [
        (v) =>
          !v || /\d/g.test(v) || "Phone must be numbers only (ex. 7803334444)",
        (v) =>
          !v ||
          v.length == 10 ||
          v.length == 9 ||
          "Phone number must be 9 - 10 digits no spaces or characters (format 7803334444)",
        (v) => (v && !!v) || "Number is required",
      ],

      email: [
        (v) => (v && !!v) || "E-mail is required",
        (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
      ],

      number: [
        (v) =>
          v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",
      ],

      month: [
        (v) =>
          v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",
        (v) => (v >= 0 && v <= 11) || "Input must be between 0 and 11",
      ],

      date: [
        (v) =>
          (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
          "Invalid date. Format should be YYYY-MM-DD",
      ],
    },

    // panels
    panels: [0],

    // personal information options
    salutation_options: ["Mr", "Mrs", "Dr", "Rev", "Miss", "Judge"],
    language_preference_options: ["English", "French"],

    marital_status_options: [
      "Single",
      "Married",
      "Common Law",
      "Separated",
      "Divorced",
      "Widowed",
      "Other",
    ],

    residential_status_options: [
      "I own my own home",
      "I currently rent",
      "I currently live with my parents",
      "Other",
    ],

    contact_type_options: [
      "Home Phone Number",
      "Cell Phone Number",
      "Work Phone Number",
      "Email",
    ],

    // headers
    header_note: [{ text: "Note", value: "note" }],

    header_credit_score: [
      { text: "Score", value: "credit_score" },
      { text: "Date Pulled", value: "date_pulled" },
    ],

    header_relationship: [
      { text: "Name", value: "name" },
      { text: "Email", value: "cust_2.email" },
      { text: "Phone number", value: "cust_2.cell_phone_number" },
      { text: "Relationship", value: "type" },
    ],

    header_identifications: [
      { text: "Identification Type", value: "identification_type" },
      { text: "Issuing Country", value: "issuing_country" },
      { text: "ID Number", value: "id_number" },
      { text: "Expiry Date", value: "expiry_date" },
    ],

    header_address: [
      { text: "Number", value: "street_number" },
      { text: "Name", value: "street_name" },
      { text: "Type", value: "street_type" },
      { text: "Direction", value: "street_direction" },
      { text: "Unit", value: "unit" },
      { text: "Postal Code", value: "postal_code" },
      { text: "City", value: "city" },
      { text: "Province", value: "province" },
      { text: "Years", value: "years" },
      { text: "Months", value: "months" },
      { text: "Present", value: "present" },
    ],

    header_employment: [
      { text: "Employer Name", value: "employer_name" },
      { text: "Job Title", value: "job_title" },
      { text: "Income Amount", value: "income_amount" },
      { text: "Years", value: "time_at_this_company_years" },
      { text: "Months", value: "time_at_this_company_months" },
      { text: "Type", value: "employment_type" },
      { text: "Present", value: "current_job" },
    ],

    header_property: [
      {
        text: "Number",
        value: "address.street_number",
      },

      {
        text: "Name",
        value: "address.street_name",
      },

      {
        text: "Type",
        value: "address.street_type",
      },

      {
        text: "Direction",
        value: "address.street_direction",
      },

      {
        text: "Unit",
        value: "address.unit",
      },

      {
        text: "Postal Code",
        value: "address.postal_code",
      },

      {
        text: "City",
        value: "address.city",
      },

      {
        text: "Province",
        value: "address.province",
      },

      {
        text: "Type",
        value: "prop_style.property_type",
      },

      {
        text: "Purchase Price",
        value: "purchase_price",
      },

      {
        text: "Purchase Date",
        value: "purchase_date",
      },
    ],

    header_additional_income: [
      { text: "Income Type", value: "income_type" },
      { text: "Income Period", value: "income_period" },
      { text: "Income Amount", value: "income_amount" },
      { text: "Note", value: "note.note" },
    ],

    header_asset: [
      { text: "Asset Type", value: "asset_type" },
      { text: "Value", value: "value" },
      { text: "Additional Description", value: "additional_description" },
    ],

    header_liability: [
      { text: "Liability Type", value: "liability_type" },
      { text: "Balance", value: "balance" },
      { text: "Payment", value: "payment" },
      { text: "Maturity Date", value: "maturity_date" },
      { text: "Single or Joint?", value: "single" },
      { text: "Additional Description", value: "additional_description" },
    ],

    customer: {
      salutation: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      birthday: null,
      email: null,
      phone: null,
      // cell_phone_number: null,
      // home_phone_number: null,
      // work_phone_number: null,
      social_insurance_number: null,
      language_preference: null,
      marital_status: null,
      marital_status_note: null,
      dependents: null,
      send_email_status_communications: false,
      first_home: false,
      current_residential_status: null,
      archived: false,

      contact_infos: [
        {
          order: 1,
          type: "Email",
          number: null,
          email: null,
          note: null,
        },

        {
          order: 2,
          type: "Cell Phone Number",
          number: null,
          email: null,
          note: null,
        },
      ],

      // financial information data
      business_for_self: false,
      business_self_notes: [],

      variable_income: false,
      variable_income_notes: [],

      power_of_attorney: false,
      poa_notes: [],

      required_mortgage_suitability_review: false,

      // credit score data
      no_credit_pulled: false,

      credit_scores: [],

      // relationship data
      new_relationship_data: {},
      relationship_data: [],

      // identification data
      new_identification_data: {},
      identification_data: [],

      // address data
      new_address_data: {},
      address_data: [],

      // employment data
      new_employment_data: {},
      employment_data: [],

      // property data
      new_property_data: {},
      property_data: [],

      // additional income data
      new_additional_income_data: {},
      additional_income_data: [],

      // asset data
      new_asset_data: {},
      asset_data: [],

      // liability data
      new_liability_data: {},
      liability_data: [],
    },

    customers: [],
  }),

  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    },

    scrollToElement(element) {
      window.scrollTo({
        top: this.getOffsetTop(element) - 100, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    // basic information
    handle_add_contact_info() {
      let new_order = 0;

      if (this.customer.contact_infos.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.contact_infos.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_contact_info.order = new_order;

      this.customer.contact_infos.push(this.new_contact_info);

      this.new_contact_info = {
        type: null,
        number: null,
        note: null,
        email: null,
      };
    },

    handle_add_old_data(type) {
      let new_order = 0;

      if (this.customer.contact_infos.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.contact_infos.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_contact_info.order = new_order;

      switch (type) {
        case "phone":
          if (this.customer.phone !== null) {
            this.new_contact_info.type = "Cell Phone Number";
            this.new_contact_info.number = this.customer.phone;
            this.customer.phone = null;
          } else {
            alert("No old phone number found");
          }
          break;
        case "email":
          if (this.customer.email !== null) {
            this.new_contact_info.type = "Email";
            this.new_contact_info.email = this.customer.email;
            this.customer.email = null;
          } else {
            alert("No old email found");
          }
          break;
      }

      this.customer.contact_infos.push(this.new_contact_info);

      this.new_contact_info = {
        type: null,
        number: null,
        note: null,
        email: null,
      };
    },

    check_if_contact_info_exist(i) {
      const contact_info = this.customer.contact_infos[i];

      let contact_info_exist = null;

      switch (contact_info.type) {
        case "Home Phone Number":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.number === contact_info.number
          );
          break;

        case "Cell Phone Number":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.number === contact_info.number
          );
          break;

        case "Work Phone Number":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.number === contact_info.number
          );
          break;

        case "Email":
          contact_info_exist = this.existing_contact_infos.find(
            (el) =>
              el.type === contact_info.type && el.email === contact_info.email
          );
          break;

        default:
          break;
      }

      if (contact_info_exist) {
        console.log("this contact exist", contact_info_exist);

        this.confirming_contact_info = true;
        this.temp_contact_info = contact_info_exist;
        this.temp_contact_info.index = i;
      } else {
        console.log("this contact does not exist on the database");
      }
    },

    handle_confirm_contact_info() {
      const i = this.temp_contact_info.index;
      delete this.temp_contact_info.index;

      switch (this.temp_contact_info.type) {
        case "Home Phone Number":
          this.customer.contact_infos[i].number = this.temp_contact_info.number;
          this.customer.contact_infos[i].id = this.temp_contact_info.id;
          break;

        case "Cell Phone Number":
          this.customer.contact_infos[i].number = this.temp_contact_info.number;
          this.customer.contact_infos[i].id = this.temp_contact_info.id;
          break;

        case "Work Phone Number":
          this.customer.contact_infos[i].number = this.temp_contact_info.number;
          this.customer.contact_infos[i].id = this.temp_contact_info.id;
          break;

        case "Email":
          this.customer.contact_infos[i].email = this.temp_contact_info.email;
          this.customer.contact_infos[i].id = this.temp_contact_info.id;
          break;

        default:
          break;
      }

      console.log("this.customer.contact_infos", this.customer.contact_infos);
      this.confirming_contact_info = false;
      this.temp_contact_info = null;
    },

    handle_close_confirm_contact_info() {
      const i = this.temp_contact_info.index;
      delete this.temp_contact_info.index;

      switch (this.temp_contact_info.type) {
        case "Home Phone Number":
          this.customer.contact_infos[i].number = null;
          break;

        case "Cell Phone Number":
          this.customer.contact_infos[i].number = null;
          break;

        case "Work Phone Number":
          this.customer.contact_infos[i].number = null;
          break;

        case "Email":
          this.customer.contact_infos[i].email = null;
          break;

        default:
          break;
      }

      this.confirming_contact_info = false;
      this.temp_contact_info = null;
    },

    // financial information section
    // -- poa note
    handle_save_poa_note() {
      let new_order = 0;

      if (this.customer.poa_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.poa_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_poa_note.order = new_order;

      this.customer.poa_notes.push(this.new_poa_note);
      this.adding_poa_note = false;
      this.new_poa_note = { note: "" };
    },

    handle_updating_poa_note(data) {
      if (this.adding_poa_note) {
        alert("Please save the note you are adding before updating it.");
        return;
      } else {
        this.updating_poa_note = true;
        this.new_poa_note = data;
      }
    },

    handle_save_update_poa_note() {
      const index = this.customer.poa_notes.findIndex(
        (item) => item.order === this.new_poa_note.order
      );

      this.customer.poa_notes[index].note = this.new_poa_note.note;
      this.updating_poa_note = false;
      this.new_poa_note = { note: "" };
    },

    handle_delete_poa_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_poa_notes.forEach((el) => {
          const index = this.customer.poa_notes.findIndex(
            (item) => item.order === el.order
          );

          this.customer.poa_notes.splice(index, 1);
        });

        this.selected_poa_notes = [];
      } else {
        console.log("deleting poa note was canceled.");
        this.selected_poa_notes = [];
      }
    },

    handle_save_credit_score() {
      let new_order = 0;

      if (this.customer.credit_scores.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.credit_scores.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_credit_score.order = new_order;

      this.customer.credit_scores.push(this.new_credit_score);
      this.adding_credit_score = false;
      this.new_credit_score = { credit_score: null, date_pulled: null };
    },

    handle_updating_credit_score(data) {
      if (this.adding_credit_score) {
        alert(
          "Please save the credit score you are adding before updating it."
        );
        return;
      } else {
        this.updating_credit_score = true;
        this.new_credit_score = data;
      }
    },

    handle_save_update_credit_score() {
      const index = this.customer.credit_scores.findIndex(
        (item) => item.order === this.new_credit_score.order
      );

      this.customer.credit_scores[index].credit_score =
        this.new_credit_score.credit_score;
      this.customer.credit_scores[index].date_pulled =
        this.new_credit_score.date_pulled;
      this.updating_credit_score = false;
      this.new_credit_score = { credit_score: null, date_pulled: null };
    },

    handle_delete_credit_score() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_credit_score.forEach((el) => {
          const index = this.customer.credit_scores.findIndex(
            (item) => item.order === el.order
          );

          this.customer.credit_scores.splice(index, 1);
        });

        this.selected_credit_score = [];
      } else {
        console.log("deleting credit score was canceled.");
        this.selected_credit_score = [];
      }
    },

    // relationship section

    handle_save_relationship(data) {
      let new_order = 0;

      if (this.customer.relationship_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.relationship_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.relationship_data.push(data);
      this.customer.adding_relationship = false;
      this.adding_relationship = false;
    },

    handle_updating_relationship(data) {
      if (this.adding_relationship) {
        alert(
          "Please save the relationship you are adding before updating it."
        );
        return;
      } else {
        this.updating_relationship = true;
        this.customer.new_relationship_data = data;
      }
    },

    handle_save_update_relationship(data) {
      const index = this.customer.relationship_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.relationship_data.splice(index, 1, data);
      this.updating_relationship = false;
    },

    handle_delete_relationship() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_relationship.forEach((el) => {
          const index = this.customer.relationship_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.relationship_data.splice(index, 1);
        });

        this.selected_relationship = [];
      } else {
        console.log("deleting relationship was canceled.");
        this.selected_relationship = [];
      }
    },

    // identification section

    handle_save_identification(data) {
      let new_order = 0;

      if (this.customer.identification_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.identification_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.identification_data.push(data);
      this.adding_identification = false;
    },

    handle_save_update_identification(data) {
      const index = this.customer.identification_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.identification_data.splice(index, 1, data);
      this.updating_identification = false;
    },

    handle_updating_identification(data) {
      if (this.adding_identification) {
        alert(
          "Please save the identification you are adding before updating it. "
        );
        return;
      } else {
        this.updating_identification = true;
        this.customer.new_identification_data = data;
      }
    },

    handle_delete_identification() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_identification.forEach((el) => {
          const index = this.customer.identification_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.identification_data.splice(index, 1);
        });

        this.selected_identification = [];
      } else {
        console.log("deleting identification was canceled.");
        this.selected_identification = [];
      }
    },

    // address section

    handle_save_address(data) {
      let new_order = 0;

      if (this.customer.address_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.address_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.address_data.push(data);
      this.adding_address = false;
    },

    handle_save_update_address(data) {
      const index = this.customer.address_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.address_data.splice(index, 1, data);

      this.updating_address = false;
    },

    handle_updating_address(data) {
      if (this.adding_address) {
        alert(
          "You are currently adding a new address. Please finish adding it first before updating an existing address."
        );

        return;
      } else {
        this.updating_address = true;

        this.customer.new_address_data = data;
      }
    },

    handle_delete_address() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_address.forEach((el) => {
          const index = this.customer.address_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.address_data.splice(index, 1);
        });

        this.selected_address = [];
      } else {
        console.log("deleting address was canceled.");
        this.selected_address = [];
      }
    },

    // employment section

    handle_save_employment(data) {
      let new_order = 0;

      if (this.customer.employment_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.employment_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.employment_data.push(data);
      this.adding_employment = false;
    },

    handle_save_update_employment(data) {
      const index = this.customer.employment_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.employment_data.splice(index, 1, data);
      this.updating_employment = false;
    },

    handle_updating_employment(data) {
      if (this.adding_employment) {
        alert(
          "You are currently adding a new employment. Please finish adding it first before updating an existing employment."
        );

        return;
      } else {
        this.updating_employment = true;

        this.customer.new_employment_data = data;
      }
    },

    handle_delete_employment() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_employment.forEach((el) => {
          const index = this.customer.employment_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.employment_data.splice(index, 1);
        });

        this.selected_employment = [];
      } else {
        console.log("deleting employment was canceled.");
        this.selected_employment = [];
      }
    },

    // property section

    handle_save_property(data) {
      let new_order = 0;

      if (this.customer.property_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.property_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.property_data.push(data);
      this.adding_property = false;
      this.open_property_dialog = false;
    },

    handle_save_update_property(data) {
      const index = this.customer.property_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.property_data.splice(index, 1, data);
      this.updating_property = false;
      this.open_property_dialog = false;
      this.$refs.UpdateProperty.clear_fields();
    },

    handle_updating_property(data) {
      if (this.adding_property) {
        alert(
          "You are currently adding a new property. Please finish adding it first before updating an existing property."
        );
        return;
      } else {
        this.open_property_dialog = true;
        this.updating_property = true;

        // Ensure that this.$refs.UpdateProperty is defined and has the method
        if (
          this.$refs.UpdateProperty &&
          typeof this.$refs.UpdateProperty.updating_from_update_customer ===
            "function"
        ) {
          this.$refs.UpdateProperty.updating_from_update_customer(data);
        } else {
          this.customer.new_property_data = data;
        }
      }
    },

    handle_unassociate_property() {
      if (window.confirm("Are you sure you want to unassociate this?")) {
        this.selected_property.forEach((el) => {
          const index = this.customer.property_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.property_data.splice(index, 1);
        });

        this.selected_property = [];
      } else {
        console.log("Unassociating property was canceled.");
        this.selected_property = [];
      }
    },

    handle_unarchive_property() {
      if (window.confirm("Are you sure you want to unarchive this?")) {
        this.selected_archived_property.forEach((el) => {
          const index = this.customer.property_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.property_data[index].archived = false;
        });

        this.selected_archived_property = [];
      } else {
        console.log("unarchiving property was canceled.");
        this.selected_archived_property = [];
      }
    },

    // additional income section

    handle_save_additional_income(data) {
      let new_order = 0;

      if (this.customer.additional_income_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.additional_income_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.additional_income_data.push(data);
      this.adding_additional_income = false;
    },

    handle_save_update_additional_income(data) {
      const index = this.customer.additional_income_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.additional_income_data.splice(index, 1, data);
      this.updating_additional_income = false;
    },

    handle_updating_additional_income(data) {
      if (this.adding_additional_income) {
        alert(
          "You are currently adding a new additional income. Please finish adding it first before updating an existing additional income."
        );

        return;
      } else {
        this.updating_additional_income = true;

        this.customer.new_additional_income_data = data;
      }
    },

    handle_delete_additional_income() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_additional_income.forEach((el) => {
          const index = this.customer.additional_income_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.additional_income_data.splice(index, 1);
        });

        this.selected_additional_income = [];
      } else {
        console.log("deleting additional income was canceled.");
        this.selected_additional_income = [];
      }
    },

    // asset section

    handle_save_asset(data) {
      let new_order = 0;

      if (this.customer.asset_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.asset_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.asset_data.push(data);
      this.adding_asset = false;
    },

    handle_save_update_asset(data) {
      const index = this.customer.asset_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.asset_data.splice(index, 1, data);
      this.updating_asset = false;
    },

    handle_updating_asset(data) {
      if (this.adding_asset) {
        alert(
          "You are currently adding a new asset. Please finish adding it first before updating an existing asset."
        );

        return;
      } else {
        this.updating_asset = true;

        this.customer.new_asset_data = data;
      }
    },

    handle_delete_asset() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_asset.forEach((el) => {
          const index = this.customer.asset_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.asset_data.splice(index, 1);
        });

        this.selected_asset = [];
      } else {
        console.log("deleting asset was canceled.");
        this.selected_asset = [];
      }
    },

    // liability section

    handle_save_liability(data) {
      let new_order = 0;

      if (this.customer.liability_data.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.customer.liability_data.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.customer.liability_data.push(data);
      this.customer.adding_liability = false;
      this.adding_liability = false;
    },

    handle_save_update_liability(data) {
      const index = this.customer.liability_data.findIndex(
        (item) => item.order === data.order
      );

      this.customer.liability_data.splice(index, 1, data);
      this.updating_liability = false;
    },

    handle_updating_liability(data) {
      if (this.adding_liability) {
        alert(
          "You are currently adding a new liability. Please finish adding it first before updating an existing liability."
        );

        return;
      } else {
        this.updating_liability = true;

        this.customer.new_liability_data = data;
      }
    },

    handle_delete_liability() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_liability.forEach((el) => {
          const index = this.customer.liability_data.findIndex(
            (item) => item.order === el.order
          );

          this.customer.liability_data.splice(index, 1);
        });

        this.selected_liability = [];
      } else {
        console.log("deleting liability was canceled.");
        this.selected_liability = [];
      }
    },

    address_time_at_residence(data) {
      let totalMonths = 0;

      for (let i = 0; i < data.length; i++) {
        if (data[i].archived === true) {
          continue;
        }
        totalMonths += Number(data[i].years) * 12 + Number(data[i].months);
      }

      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      switch (true) {
        case years === 1 && months === 0:
          return { text: "1 year", year: years };
        case years === 1 && months === 1:
          return { text: "1 year and 1 month", year: years };
        case years === 1:
          return { text: `${years} years and ${months} months`, year: years };
        case months === 1:
          return { text: `${years} years and ${months} month`, year: years };
        case months === 0 && years === 0:
          return { text: "0", year: years };
        default:
          return { text: `${years} years and ${months} months`, year: years };
      }
    },

    time_at_job(data) {
      let totalMonthsCompany = 0;

      for (let i = 0; i < data.length; i++) {
        if (data[i].archived === true) {
          continue;
        }
        totalMonthsCompany +=
          Number(data[i].time_at_this_company_years) * 12 +
          Number(data[i].time_at_this_company_months);
      }

      const years = Math.floor(totalMonthsCompany / 12);
      const months = totalMonthsCompany % 12;

      switch (true) {
        case years === 1 && months === 0:
          return { text: "1 year", year: years };
        case years === 1 && months === 1:
          return { text: "1 year and 1 month", year: years };
        case years === 1:
          return { text: `${years} years and ${months} months`, year: years };
        case months === 1:
          return { text: `${years} years and ${months} month`, year: years };
        case months === 0 && years === 0:
          return { text: "0", year: years };
        default:
          return { text: `${years} years and ${months} months`, year: years };
      }
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0 && amount !== null && amount !== undefined) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },

    handle_submit() {
      // emit data to parent
      console.log("sending this data to strapi: ", this.customer);
      this.$emit("handle_submit", this.customer);
    },

    handle_cancel() {
      switch (this.viewed_as) {
        case "create":
          this.$router.push(`/dashboard/admin/customer/list-all-customers`);
          break;

        case "update":
          this.$router.push(
            `/dashboard/admin/customer/read/${this.existing_customer.id}`
          );
          break;

        case "create_from_deal":
        case "update_from_deal":
          this.$router.push(
            `/dashboard/admin/deal/read/${this.$route.query.deal_id}`
          );
          break;

        default:
          // Handle other cases or do nothing
          break;
      }
    },

    async read_all_customers() {
      try {
        // Get all customers
        const response = await API().get(
          `api/internal-admin/customer/read_all_customers`
        );

        this.customers = response.data; // Set customers to response data
      } catch (error) {
        console.log(error);
      }
    },
  },

  filters: {
    ...filt,
  },

  mounted() {
    if (this.existing_customer) {
      this.customer.salutation = this.existing_customer.salutation;
      this.customer.first_name = this.existing_customer.first_name;
      this.customer.middle_name = this.existing_customer.middle_name;
      this.customer.last_name = this.existing_customer.last_name;
      this.customer.birthday = this.existing_customer.birthday;
      this.customer.email = this.existing_customer.email;
      this.customer.phone = this.existing_customer.phone
        ? this.existing_customer.phone
        : null;
      this.customer.social_insurance_number =
        this.existing_customer.social_insurance_number;
      this.customer.language_preference =
        this.existing_customer.language_preference;
      this.customer.marital_status = this.existing_customer.marital_status;
      this.customer.marital_status_note =
        this.existing_customer.marital_status_note;
      this.customer.dependents = this.existing_customer.dependents;
      this.customer.send_email_status_communications =
        this.existing_customer.send_email_status_communications;
      this.customer.first_home = this.existing_customer.first_home;
      this.customer.archived = this.existing_customer.archived;
      this.customer.current_residential_status =
        this.existing_customer.current_residential_status;
      this.customer.business_for_self =
        this.existing_customer.business_for_self;
      this.customer.variable_income = this.existing_customer.variable_income;
      this.customer.required_mortgage_suitability_review =
        this.existing_customer.required_mortgage_suitability_review;
      this.customer.power_of_attorney =
        this.existing_customer.power_of_attorney;
      this.customer.no_credit_pulled = this.existing_customer.no_credit_pulled;

      // basic information
      // contact infos
      this.customer.contact_infos = [];
      for (const item of this.existing_customer.contact_infos) {
        this.new_contact_info = item;
        this.handle_add_contact_info();
      }

      // financial information data
      // poa_notes
      for (const item of this.existing_customer.poa_notes) {
        this.new_poa_note = item;
        this.handle_save_poa_note();
      }

      // credit scores
      for (const item of this.existing_customer.credit_scores) {
        this.new_credit_score = item;
        this.handle_save_credit_score();
      }

      // relationship data
      for (const relationship of this.existing_customer.cust_rltns_1) {
        this.handle_save_relationship(relationship);
      }

      // identification data

      for (const id of this.existing_customer.cust_ids) {
        this.handle_save_identification(id);
      }

      for (const address of this.existing_customer.addresses) {
        this.handle_save_address(address);
      }

      for (const employment of this.existing_customer.cust_emps) {
        employment.gross_revenue = this.formatCurrency(
          String(employment.gross_revenue)
        );

        employment.income_amount = this.formatCurrency(
          String(employment.income_amount)
        );

        this.handle_save_employment(employment);
      }

      for (const prop of this.existing_customer.props) {
        prop.purchase_price = this.formatCurrency(String(prop.purchase_price));
        this.handle_save_property(prop);
      }

      for (const additional_income of this.existing_customer.cust_ad_incs) {
        additional_income.income_amount = this.formatCurrency(
          String(additional_income.income_amount)
        );

        this.handle_save_additional_income(additional_income);
      }

      for (const asset of this.existing_customer.cust_asts) {
        asset.value = this.formatCurrency(String(asset.value));

        this.handle_save_asset(asset);
      }

      for (const liability of this.existing_customer.cust_liabs) {
        liability.balance = this.formatCurrency(String(liability.balance));

        liability.payment = this.formatCurrency(String(liability.payment));

        this.handle_save_liability(liability);
      }
    }

    this.read_all_customers();
  },
};
</script>

<style scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.side-nav {
  background-color: #111 !important;
  color: white !important;
  top: 100px;
  position: sticky;
  position: -webkit-sticky;
}
</style>
