<template>
  <v-form v-model="new_identification_is_valid">
    <v-card>
      <v-card-title>
        {{
          adding_identification ? "Add Identifacation" : "Update Identification"
        }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="new_identification_data.identification_type"
              :items="identification_type_options"
              label="Identification Type"
              :rules="rule.required"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_identification_data.issuing_country"
              label="Issuing Country"
              :rules="rule.required"
              outlined
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_identification_data.id_number"
              label="ID Number"
              :rules="rule.required"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-menu
              v-model="expiry_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="new_identification_data.expiry_date"
                  label="Expiry Date"
                  outlined
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rule.date"
                  hint="YYYY-MM-DD format"
                  append-icon="mdi-calendar-blank"
                />
              </template>

              <v-date-picker
                no-title
                v-model="new_identification_data.expiry_date"
                @input="expiry_date_menu = false"
              />
            </v-menu>
          </v-col>

          <v-col>
            <v-textarea
              v-model="new_identification_data.note.note"
              label="Notes"
              auto-grow
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="adding_identification"
          :disabled="!new_identification_is_valid"
          color="primary"
          @click="$emit('save_add', new_identification_data)"
        >
          save
        </v-btn>

        <v-btn
          v-if="updating_identification"
          :disabled="!new_identification_is_valid"
          color="primary"
          @click="$emit('save_update', new_identification_data)"
        >
          update
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "AddIdentification",

  props: {
    adding_identification: {
      type: Boolean,
    },

    updating_identification: {
      type: Boolean,
    },

    rule: {
      type: Object,
    },

    identification_data: {
      type: Object,
    },
  },

  data: () => ({
    new_identification_is_valid: false,
    expiry_date_menu: false,

    // identification options
    identification_type_options: [
      "Birth Certificate",
      "Driver's License",
      "Passport",
      "Health Insurance Card",
      "Permanent Resident Card",
      "Citizenship Certificate",
      "IMM 1000 Immigration Form",
      "IMM 1442 Citizenship and Immiration Canada",
      "Other",
    ],

    // initialize identification data
    new_identification_data: {
      identification_type: null,
      issuing_country: null,
      id_number: null,
      expiry_date: null,
      note: {
        note: null,
        type: "Identification",
      },
      archived: false,
    },
  }),

  mounted() {
    if (this.updating_identification) {
      this.new_identification_data = this.identification_data;

      if (!this.new_identification_data.note) {
        this.new_identification_data.note = {
          note: null,
          type: "Identification",
        };
      }
    }
  },
};
</script>
