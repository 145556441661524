<template>
  <v-form v-model="new_additional_income_is_valid">
    <v-card>
      <v-card-title>
        {{
          adding_additional_income
            ? "Adding Additional Income"
            : "Updating Additional Income"
        }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="new_additional_income_data.income_type"
              label="Income Type"
              :items="additional_income_type_options"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_additional_income_data.income_period"
              label="Income Period"
              :items="additional_income_period_options"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_additional_income_data.income_amount"
              label="Income Amount"
              outlined
              hide-details="auto"
              @blur="
                new_additional_income_data.income_amount = formatCurrency(
                  new_additional_income_data.income_amount
                )
              "
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row>
          <v-col>
            <v-textarea
              v-model="new_additional_income_data.note.note"
              label="Add Notes"
              outlined
              auto-grow
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="adding_additional_income"
          :disabled="!new_additional_income_is_valid"
          color="primary"
          @click="$emit('save_add', new_additional_income_data)"
        >
          save
        </v-btn>

        <v-btn
          v-if="updating_additional_income"
          :disabled="!new_additional_income_is_valid"
          color="primary"
          @click="$emit('save_update', new_additional_income_data)"
        >
          update
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "AddAdditionalIncome",

  props: {
    adding_additional_income: {
      type: Boolean,
    },

    updating_additional_income: {
      type: Boolean,
    },

    rule: {
      type: Object,
    },

    additional_income_data: {
      type: Object,
    },
  },

  data: () => ({
    new_additional_income_is_valid: false,

    // additional income options
    additional_income_type_options: [
      "Child Support",
      "Pension",
      "Interest Income",
      "Alimony",
      "Other",
    ],

    additional_income_period_options: [
      "Annual",
      "Semi-Annual",
      "Quarterly",
      "Monthly",
      "Semi-Monthly",
      "Bi-Weekly",
      "Weekly",
    ],

    // initialize additional_income data
    new_additional_income_data: {
      income_type: null,
      income_period: null,
      income_amount: null,
      note: {
        note: null,
        type: "Additional Income",
      },
      archived: false,
    },
  }),

  methods: {
    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    if (this.updating_additional_income) {
      this.new_additional_income_data = this.additional_income_data;

      if (!this.new_additional_income_data.note) {
        this.new_additional_income_data.note = {
          note: null,
          type: "Additional Income",
        };
      }
    }
  },
};
</script>
